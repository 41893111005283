.grid_calendar{
  overflow:auto;
}

.grid_selector_name {
  display: inline-block;
  font-size: 1em;
  margin-right: 5px;
  cursor: pointer;
}

.grid_selector_inactive {
  color: #4e5e7c;
}

.grid_selector_active {
  color: #1e7be2;
}

.grid_selector {
  display: inline-block;
  float: right;
  margin-top: 21px;
}

.grid_selector_today {
  display: inline-block;
  margin-right: 20px;
}

.grid_selector_today_text {
  padding: 10px 20px 10px 20px;
}

.grid_selector_toggle{
  display: inline-block;
  cursor: pointer;
  border: 1px solid #1E7BE2;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.grid_selector_day{
  border-radius: 6px 0px 0px 6px;
  width: 77px;
  text-align: center;
  color: #333333;
}

.grid_selector_day_active{
  background: #1E7BE2;
  color: #FFFFFF;
}

.grid_selector_week{
  border-radius: 0px 6px 6px 0px;
  width: 77px;
  text-align: center;
  color: #333333;
}

.grid_selector_week_active{
  background: #1E7BE2;
  color: #FFFFFF;
}

.hidden{
  display: none;
}

.btn-primary {
  background-color: #1E7BE2;
  border-color:#1E7BE2 ;
}
.btn-primary:hover{
  background-color: #1E7BE2;
  border-color:#1E7BE2 ;
}