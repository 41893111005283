.grid_column {
  display: inline-block;
  width: 13.56%;
  vertical-align: top;
  position: relative;

  .grid_cell {
    background-color: white;
    border: 1px solid #f2f2f2;
  }

  .wider_border {
    border-top: 2px solid #f2f2f2;
  }
}