.closinghours {
  position: absolute;
  overflow: hidden;

  .closinghours-content {
    position: relative;
    width: 100%;
  }

  .closinghours-name {
    font-size: 0.9rem;
    font-weight: bold;
    margin: 5px;
  }
}

.closinghours.closed {
  background: repeating-linear-gradient(
                  -45deg,
                  rgba(#fff, 0) 5px,
                  rgba(#fff, 0) 16px,
                  rgba(#000, 0.05) 16px,
                  rgba(#000, 0.05) 19px
  );
}

.closinghours.closed_special {
  border-radius: 3px;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  color: #660000;
  $color: #e25d56;
  background-color: rgba($color, 0.3);
  border-color: rgba($color, 0.2);
}