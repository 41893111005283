.room-selector {
  .btn-primary {
    color: #1E7BE2;
    background-color: rgba(#fff, 0.0);
    min-width: 120px !important;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-right: 15px;
    border-radius: 0.25rem !important;
    border: 2px solid #1E7BE2;
    padding: 2px 2px 2px 2px;
    font-size: 1.0em;
  }
  .btn-primary:hover{
    color: #fff;
    background-color: #1E7BE2;
  }
  .btn-primary.selected{
    color: #fff;
    background-color: #1E7BE2;
  }
}