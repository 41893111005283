.calendar_date_header{
  border: 1px solid #f2f2f2;
  height: 63px;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  
  @-moz-document url-prefix() {
    scrollbar-color: #ffffff #ffffff;
    scrollbar-width: auto;
  }
  
  &::-webkit-scrollbar {
    display: transparent;
    overflow: hidden;
  }

  @media screen and (min-device-width: 320px) and (max-device-width: 1366px){
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }

  .hour_column{
    display: inline-block;
    width: 5%;
    height: inherit;
    background-color: white;
    vertical-align: bottom;
    border: inherit;
  }
  .day{
    display: inline-block;
    width: 13.56%;
    height: inherit;
    text-align: center;
    background-color: white;
    vertical-align: bottom;
    padding-top: 8px;
    border: inherit;
  }
  .day_name{
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 128.91%;
    align-items: center;
    text-align: center;
    color: #333333;
  }
  .day_number{
    margin: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 128.91%;
    align-items: center;
    text-align: center;
    color: #333333;
  }
  .day_active{
    height: 30px;
    width: 30px;
    padding-top: 5px;
    background-color: #E6E6E6;
    border-radius: 50%;
    display: inline-block;
  }
}