.modal-body {
  cursor: auto;
  z-index: 2;

  .group-label {
    text-transform: none;
    text-align: -webkit-left;
    text-align: -moz-left;
    font-weight: bold;
    white-space: nowrap;
    margin-left: auto;
    padding-top: 3px;
    color: inherit;
  }

  label {
    padding: 4px 0;
    margin-bottom: 0;
  }

  .chooser_label {
    font-size: 13.3333px;
  }

  .form-row {
    padding: 10px;
    border: 1px solid grey;
    margin: 10px;
  }

  .sc-bdVaJa {
    display: block;
  }

  .btn-cancel {
    background-color: white;
    color: royalblue;
    border: 1px royalblue solid !important;
    margin-right: 20px;
  }

  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
  }

  .popup_inner {
    margin: 30px auto;
    background: white;
    padding: 0 30px 30px 30px;
    max-width: 700px;
  }

  .btn-customer-lookup {
    color: blue;
  }

  .row-form-button {
    margin-top: 10px;
    text-align: right;
  }

  .redo-fieldset {
    border: 1.5px solid lightgrey;
    padding: 20px 25px 25px;
    margin: 20px 0;
    width: 100%;
  }

  .days-of-week-fieldset {
    padding: 0 20px 20px;
    margin: 15px 0 15px 0;
  }

  .days-of-week-fieldset.standard {
    border: 0.5px solid lightgrey;
  }

  .days-of-week-fieldset.error {
    border: 2px solid #B30000;
  }


  .redo-legend {
    color: #000;
    font-weight: 700;
    padding: 0 10px;
    font-size: 16px;
    width: auto;
  }

  .appointment-flex {
    display: flex;
  }

  .customer-lookup {
    cursor: pointer;
    padding-top: 2.3em;
    padding-left: 20px;
    font-size: small;
    color: #1E7BE2;

    img {
      height: 15%;
      width: 15%;
    }
  }

  .react-datepicker-wrapper, .react-datepicker__input-container, .datepicker__input {
    width: 100%;
  }

  .appointment-text-area div.dnjdx * {
    height: 100%;
  }

  .form-control, .datepicker__input {
    max-height: 26px;
  }

  .datepicker__input {
    margin-bottom: unset !important;
  }

  .datepicker__input.rdf.disabled {
    background-color: #f9f9f9;
  }

  .form-control:disabled {
    background-color: #f9f9f9;
  }

  .day-grayed-out {
    color: #ccc;
  }

  .css-109onse-indicatorSeparator, .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-6c8979-MenuList, .css-4ljt47-MenuList {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .appointment-text-area .form-control {
    max-height: 93px;
    height: 93px !important;
  }

  .btn-primary, .btn-cancel {
    border-radius: 35px !important;
    width: 200px !important;
  }

  .btn-cancel {
    color: blue !important;
  }

  button:disabled {
    color: lightgrey !important;
    background-color: #1E7BE2;
  }

  .link-button {
    font-size: 30pt !important;
    cursor: pointer;
    text-align: right;
    text-decoration: none;
    background-color: transparent;
    border: none;
    display: inline;
    margin: 0;
    padding: 0;
    left: 20px;
    position: relative;
  }

  .link-button:hover,
  .link-button:focus {
    text-decoration: none;
  }

  // hide default error messages generated by react-dynamic-forms
  .help-block.has-error {
    display: none;
  }

  .group-required {
    display: none;
  }

  .datepicker__input.rdf.disabled {
    color: #aaa;
  }

  .datepicker__input.rdf {
    padding-right: 12px;
    padding-left: 12px;
  }

  .form-control, .datepicker__input.rdf {
    font-size: 12px;
    padding: 0 12px;
  }

  .input-error {
    border: 2px solid #B30000;
  }

  .checkbox-error {
    color: #B30000;
  }

  .datepicker-error {
    border: 2px solid #B30000 !important;
  }

  .form-control:focus.input-error {
    box-shadow: none;
  }

  input[type="checkbox"] {
    cursor: pointer;
    margin-right: 5px;
    margin-left: -18px;
    margin-top: 2px;
    vertical-align: -3px;
  }

  .checkbox {
    word-break: break-word;
  }

  .checkbox.disabled {
    color: #aaa;
  }

  .checkbox label {
    cursor: pointer;
    font-size: 12px;
    margin-left: 18px;
  }

  .day-of-week {
    display: inline-block;

    label {
      margin-top: 20px;
    }

  }

  .group-label {
    text-align: left;
  }

  .group-label label {
    min-height: 20px;
  }

  .css-2b097c-container, .css-14jk2my-container {
    font-size: 12px !important;
  }

  .css-1fhf3k1-control, .css-yk16xz-control, .css-1pahdxg-control {
    height: 27px;
    min-height: 0;
    cursor: pointer;
    align-content: center;
  }

  .dropdown-error {
    .css-yk16xz-control {
      border: 2px solid #B30000;
    }

    .css-1pahdxg-control {
      border: 2px solid #B30000;
      box-shadow: none;
    }
  }

  .css-1fhf3k1-control{
    background-color: #f9f9f9;
    border-color: hsl(0,0%,80%);
  }
  .css-26l3qy-menu {
    margin-top: 0 !important;
  }

  .css-6c8979-MenuList > *, .css-4ljt47-MenuList > * {
    cursor: pointer;
  }

  .form-element {
    margin-bottom: 10px;
  }

  .css-9gakcf-option {
    background-color: white;
    font-weight: bold;
    color: black;
  }

  .css-9gakcf-option:hover {
    background-color: #DEEBFF;
  }

  .interval {
    font-size: 12px;

    .sc-bdVaJa .gsyFJO { // element with that class is autogenerated and intended to contain field label which we do not display in field `interval`
      display: none;
    }

    .col-2, .col-6 {
      margin-top: 5px;
    }

    span {
      margin-top: 5px;
    }
  }

  .following-week-days {
    font-size: 12px;
  }

  input[type="time"] {
    font-size: 13px;
    border-radius: 4px;
    box-shadow: inset 0 2px 2px #e9e9e9;
    border: 1px solid #aeaeae;
    line-height: 16px;
    padding: 6px 10px 5px;

    &:focus {
      outline: none;
      border-color: #aeaeae;
      box-shadow: inset 0 2px 2px #e9e9e9, 0 0 10px 0 rgba(73, 107, 125, 0.3);
    }
  }

  .react-datepicker-time__input-container {
    display: inline-block;
  }

  .react-datepicker-time__caption {
    display: inline-block;
  }

  .react-datepicker-time__input {
    margin-left: 10px;
    display: inline-block;
  }

  .react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;
  }

  .appointment-date {
    margin-bottom: 10px;
  }

}



