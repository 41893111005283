.appointment-filter {

  word-break: break-word;
  max-width: 250px;

  .filter-header {
    line-height: 60px;
    align-content: center;
  }

  .filter-label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  img {
    cursor: pointer;
    margin-right: 25px;
    float: right;
    margin-top: 25px;
  }

  // custom checkbox
  .container {
    display: block;
    position: relative;
    padding-left: 27px;
    margin-bottom: 10px;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .container span {
    margin-top: 2px;
    &.cancelled {
        margin-top: 22px;
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #005ABB;
    border: 1px solid #005ABB;
    border-radius: 3px;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}