@font-face {
  font-weight: normal;
  font-style: normal;
}

.customer_search_modal-container {
  color: #333333;

  .background_greyed {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    z-index: 2;

    .customer_search_modal {
      background: #fff;
      margin: 5% auto;
      padding: 28px 46px 46px 46px;
      min-width: 700px;
      max-width: 920px;

      .close_button {
        margin-top: -10px;
        margin-right: -30px;
        display: flex;
        justify-content: flex-end;

        img {
          cursor: pointer;
        }
      }

      .back_to_overview {
        cursor: pointer;
        display: inline-block;

        img {
          margin-top: -4px;
        }

        span {
          margin-left: 10px;
        }
      }

      span {
        color: #1E7BE2;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
      }

    }

  }

  .button-blue-full-bg {
    margin-top: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: white;
    width: 225px;
    height: 47px;
    background: #1E7BE2;
    border-radius: 48px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    border-style: none;
  }

  .button-blue-full-bg:disabled {
    color: lightgrey;
    opacity: 0.65;
  }

  .button-blue-no-bg {
    background-color: white;
    border: 1px solid #1E7BE2;
    border-radius: 48px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1E7BE2;
    min-height: 40px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    line-height: 24px;
    padding-left: 20px;
    padding-right: 20px;
    white-space: normal;
  }

  fieldset {
    margin-top: 20px;
    border: 1px solid #BFBFBF;
    border-radius: 3px;
    padding: 30px 36px 34px;
  }

  legend {
    font-variant: small-caps;
    width: auto;
    padding: 0 13px 10px 13px;
    margin-left: -13px;
    font-size: 26px;
  }

  .no-results {
    font-size: 20px;
    text-align: center;
    margin-top: 130px;
  }

  h1 {
    font-size: 32px;
    line-height: 49px;
  }

  .customer_search-form {

    legend img {
      width: 25px;
      height: 23px;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    .form_input-wrapper {
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 5px;
      }

      input[type=text] {
        height: 46px;
        width: 100%;
        border: 1px solid #B3B3B3;
        border-radius: 3px;
        margin-bottom: 20px;
        padding-left: 16px;
      }
    }
  }

  .customer_search-result {
    .column_header {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      min-height: 50px;
    }

    .customer-row {
      word-wrap: break-word;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 48px;
      min-height: 70px;
      align-items: center;
      color: #595959;
      border-top: 1px solid #E6E6E6;

      img {
        cursor: pointer;
      }
    }

    .customer-row:hover {
      background: #F5F6F7;
    }

    .customer-row > .col, .column_header > .col {
      max-width: 18.5%;
    }

    .customer-row > .col:last-of-type, .column_header > .col:last-of-type {
      max-width: 7.5%;
    }

    .customer-row:last-of-type {
      border-bottom: 1px solid #E6E6E6;
      margin-bottom: 50px;
    }

    .result-content {
      margin-left: 10px;
    }

    fieldset {
      position: relative;
      min-height: 540px;
      padding-bottom: 20px;
      padding-top: 10px;
    }

    .button-blue-full-bg {
      width: 150px;
    }

    .pagination-container {
      width: 90%;
      position: absolute;
      bottom: 10px;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

  }
}