.nav{
  .header_logo{
    display: inline-block;
  }
  .separator{
    border-right: #f2f2f2 3px solid;
  }
}

.navbar{
  width: 100%;
  justify-content: normal;
}

.header-first-icons{
  display: flex;
}

.header-second-icons{
  display: flex;
  margin-left: auto;
}
