.calendar_day_body {
  height: calc(100vh - 334px);
  position: relative;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-color: #f2f2f2;
  border-width: 2px 1px 1px 1px;
  border-style: solid;
  &::-webkit-scrollbar {
    display: none;
  }
}