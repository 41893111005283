.modal_container {
  max-width: 1000px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  fieldset {
    display: block;
    margin-top: 20px;
    margin-right: 15px;
    padding: 0.5em 0.75em 0.625em;
    border: 2px solid #CACACA;
  }

  legend {
    width: auto;
    font-size: 1em;
    font-weight: bold;
    padding: 0 7px 0 7px;
    margin: 0 0 0 15px;
  }

  fieldset.no-border {
    border: none;
  }


  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .background_greyed {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    z-index: 3;

    .confirm-modal {
      background: #fff;
      margin: 15% auto auto;
      padding: 2px 5px 20px 20px;
      max-width: 600px;

      .loader {
        border: 20px solid #f3f3f3;
        border-top: 20px solid #6A6A6A;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        animation: spin 2s linear infinite;
        margin: auto;
      }

      p {
        text-align: center;
        margin: auto;
        padding-top: 15px;
        font-size: 1.3em;
      }

      .header {
        margin-left: 5px;
        align-items: center;
      }

      ul {
        margin-top: 20px;
        margin-left: -5px;
        list-style: none;

        li {
          position: relative;
          padding-left: 1.5em;
        }

        li:before {
          content: '';
          position: absolute;
          left: 0;
          width: 1em;
          height: 1em;
          background: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%271.0%27%20encoding%3D%27utf-8%27%3F%3E%3Csvg%20width%3D%2718%27%20height%3D%2718%27%20viewBox%3D%270%200%201792%201792%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%3Cpath%20d%3D%27M1671%20566q0%2040-28%2068l-724%20724-136%20136q-28%2028-68%2028t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28%2068-28t68%2028l294%20295%20656-657q28-28%2068-28t68%2028l136%20136q28%2028%2028%2068z%27%2F%3E%3C%2Fsvg%3E') no-repeat;
        }
      }

      .close_button {
        margin-top: 15px;
        margin-right: 15px;
        display: flex;
        justify-content: flex-end;
      }

      .btn-primary {
        border-radius: 35px !important;
        width: 200px !important;
      }

      .close {
        opacity: 1 !important;
      }

    }

  }
}
