.appointment-details {
  word-break: break-all;
  background-color: white;
  position: absolute;
  width: 400px;
  bottom: -6em;
  left: 5em;
  border: #f2f2f2 solid 2px;
  border-radius: 0.4em;
  z-index: 1;
  
  &--align-left{
    left: -20em;
  }

  .appointment-details-canvas {
    padding: 1em;
  }

  .appointment-type {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 1.5em;
  }

  .appointment-details-label {
    font-weight: bold;
    font-size: 0.9em;
    padding-left: 0;
  }

  .appointment-details-value {
    font-weight: normal;
    font-size: 0.9em;
    color: black;
    display: block;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .appointment-details-buttons {
    width: 100%;
    border-top: #f2f2f2 solid 1.5px;

    .row {
      margin-bottom: 0;
    }
  }

  .close-popup {
    font-size: 1.5em;
    margin-right: 0.5em;
    cursor: pointer;
  }

  .buttons {
    margin-left: auto;
    margin-top: 0.5em;
  }

  .bottom-button {
    display: inline;
    color: #1e7be2;
    margin-left: 1em;
    cursor: pointer;

  }

  .row {
    margin-left: 0;
    margin-bottom: 0.5em;
  }
}