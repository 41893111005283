.header_icon {
  font-size: 9px;
  text-align: center;
  width: 60px;
  height: 60px;
  margin: 0 5px;
  padding: 8px 0;
  cursor: pointer;
  text-decoration: unset !important;
}

.header_icon:hover, .header_icon.highlighted, .active {
  border-radius: 100%;
  background: #f2f2f2;
}

.header_icon_name {
  color: black;
}