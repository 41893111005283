.cancel-appointment-series-modal-container {

  .background_greyed {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    z-index: 3;
  }

  .cancel-appointment-series-modal {

    background: #fff;
    margin: 15% auto auto;
    padding: 20px;
    max-width: 460px;
    border-radius: 0.2rem;

    h1 {
      font-size: 1.2rem;
      font-weight: bold;
      margin-top: 1rem;
    }

    .cancel-series-label {
      font-size: 0.9rem;
      margin-bottom: 1.5rem;
      margin-top: 0.8rem;
    }

    .no_button {
      display: inline-block;
      color: #1e7be2;
      background-color: white;
      border: 1px #1e7be2 solid;
      border-radius: 35px;
      width: 200px;
      height: 35px;
      margin-right: 20px;
    }

    .no_button:active, .no_button:focus {
      outline: none;
    }

    .yes_button {
      display: inline-block;
      color: white;
      background-color: #1e7be2;
      border-radius: 35px;
      border-color: #1e7be2;
      width: 200px;
      height: 35px;

    }

    .yes_button:active, .yes_button:focus {
      outline: none;
    }

    button {
      border-style: solid;
    }

    .close-popup {
      font-size: 2rem;
      margin-top: -20px;
      cursor: pointer;
    }

  }
}