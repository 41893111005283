.diary_content {
  display: flex;

  .sidebar {
    background-color: #313b46;
  }

  .diary_body{
    margin-right: 25px;
    margin-left: 30px;
    width: 100%;
  }

  .float-btn-appointment-modal:focus {
    box-shadow: unset;
  }

  .float-btn-appointment-modal{
    position:fixed;
    width:50px;
    height:50px;
    bottom:60px;
    right:60px;
    background-color:#1e7be2;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    box-shadow: 2px 2px 3px #999;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    z-index: 1;
  }

  .float-btn-appointment-modal:hover,
  .float-btn-appointment-modal:focus {
    text-decoration: none;
  }

  .float-btn-appointment-modal:hover{
    cursor: pointer;
  }
  .float-plus{
    font-size: xx-large;
    position: relative;
    color: white;
  }

  .row{
    margin-right: 0;
  }
}