.store-dropdown {
  position: relative;
  display: inline-block;

  .store-dropdown-content {
    display: none;
    position: absolute;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-radius: 5px;
    z-index: 1;
    right: 5px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }

  .show {
    display: block;
  }

  .dropdown-item:hover, .dropdown-item:focus {
    color: #fff;
    cursor: context-menu;
    text-decoration: none;
    background-color: #1e7be2;
    border-radius: inherit;
  }

}