.appointment {
  position: absolute;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;

  .appointment-content {
    position: relative;
    width: 100%;
  }

  .appointment-substatus {
    position: absolute;
    bottom: 20px;
    font-size: 0.6rem;
    border-radius: 3px;
    padding: 3px 5px;
    margin-left: 8px;
    font-weight: bold;
    color: white;
    cursor: pointer;

    .chevron {
      display: inline-block;
      padding-left: 5px;
      vertical-align: bottom;
    }

    .appointment-substatus-content {
      display: inline-block;
    }
  }

  .customer-name {
    font-size: 0.9rem;
    font-weight: bold;
    margin: 5px;
    overflow: hidden;
  }

  .appointment-type-name {
    font-size: 0.8rem;
    margin: 5px;
    overflow: hidden;
  }

}

.appointment.grayed-out {
  background-color: rgba(#1e7be2, 0.1);
  border-color: rgba(#1e7be2, 0.1);
  cursor: auto;

  .appointment-substatus {
    opacity: 0.5;
    cursor: auto;
  }

  .customer-name {
    color: rgb(190, 190, 190);
  }

  .appointment-type-name {
    color: rgb(190, 190, 190);
  }

}

.appointment.filtered {
  background-color: rgba(#1e7be2, 0.3);
  border-color: rgba(#1e7be2, 0.2);
}

.appointment.confirmed {
  .appointment-substatus {
    background-color: rgba(0, 0, 255, 1);
  }
}

.appointment.reservation {
  .appointment-substatus {
    background-color: rgb(66, 193, 255);
  }
}

.appointment.cancel {
  background-color:	rgba(255, 204, 204, 0.5);
  
  .customer-name {
    color: rgb(190, 190, 190);
  }

  .appointment-type-name {
    color: rgb(190, 190, 190);
  }

  .appointment-substatus {
    background-color: rgba(190, 190, 190, 1);
  }
}

.appointment.seen {
  background-color: rgba(#1e7be2, 0.2);

  .appointment-substatus {
    background-color: rgba(190, 190, 190, 1);
  }

  .customer-name {
    color: rgb(190, 190, 190);
  }

  .appointment-type-name {
    color: rgb(190, 190, 190);
  }
}

.appointment.seen.grayed-out {
  background-color: rgba(#1e7be2, 0.1);
}

.appointment.arrived {
  .appointment-substatus {
    background-color: rgba(245, 165, 44, 1);
  }
}

.appointment.initial_exam {
  .appointment-substatus {
    background-color: rgba(255, 255, 70, 1);
    color: black;
  }
}

.appointment.waiting {
  .appointment-substatus {
    background-color: rgba(228, 75, 250, 1);
  }
}

.appointment.in_consultation {
  .appointment-substatus {
    background-color: green;
  }
}

.appointment.fields {
  .appointment-substatus {
    background-color: rgba(255, 166, 224, 1);
  }
}

.appointment.did_not_attend {
  .appointment-substatus {
    background-color: rgba(255, 0, 0, 1);
  }
}

.appointment.undefined {
  .appointment-substatus {
    background-color: rgba(61, 67, 72, 1);
  }
}

.appointment-dropdown-content {
  visibility: hidden;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  left: 115%;
  z-index: 1;

  .dropdown-item {
    font-size: 0.8rem;
    border-bottom: 0.1px solid #e1e1e2;
  }

  .dropdown-item:last-child {
    border-bottom: none;
  }

  .dropdown-item:hover, .dropdown-item:focus {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    background-color: #1e7be2;
    border-radius: inherit;
  }

}

.appointment-dropdown-content.show {
  visibility: visible;
}



