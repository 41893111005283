.sidebar-component {
  $bgColor: #313b46;

  .sidebar-col {
    overflow-y: auto;
    overflow-x: hidden;

    .row {
      background-color: unset;

      .btn {
        padding-left: inherit
      }
    }
  }

  li {
    color: white;
    list-style-type: none;
  }

  .btn-sidebar:focus {
    box-shadow: unset;
  }

  .btn-sidebar {
    margin-left: 5px;
  }

  .fa {
    font-size: 1.5em;
    color: grey;
  }

  .sidebar-on {
    min-width: 255px;
  }

  .sidebar-off {
    max-width: 45px;
  }

  .datepicker {
    background-color: $bgColor;
    color: white;
    border: none;

    .date {
      font-size: 1.3em;
    }

    .day {
      color: white;
      font-size: 0.9em;
    }

    .out-of-selected-month {
      border-radius: 100%;
      opacity: 0.3;
    }

    .in-selected-month {
      border-radius: 100%;
    }

    .out-of-selected-month:hover {
      color: white;
      border-radius: 100%;
      background-color: #6E757D;

    }

    .in-selected-month:hover {
      background-color: #6E757D;
    }


   .react-datepicker__day--in-range{
      background-color: #4e5358;
    }

    .react-datepicker__day--selected {
      background-color: #6E757D;
    }

    .react-datepicker__day--in-range:hover{
      background-color: #6E757D;
    }

    .react-datepicker__header {
      background-color: $bgColor;
      border: none;
    }

    .react-datepicker__day--highlighted{
      background-color: #1e7be2;
      font-weight: normal;
    }

    .react-datepicker__day--highlighted.react-datepicker__day--selected{
      font-weight: bold;
    }

    .react-datepicker__current-month, .react-datepicker__day-name {
      color: white;
    }

    .react-datepicker__day-name {
      text-transform: uppercase;
      font-size: 0.9em;
    }

    .date_selector_wrapper {
      display: flex;
      margin-left: 0.8rem;
      align-items: center;
      flex-direction: row;

      .selectors {
        margin-left: auto;

        .date_selector_element:first-child {
          margin-right: 20px;
        }
      }

    }
  }

  .no-padding {
    padding-right: 0;
    padding-left: 0;
  }

  .no-right-padding {
    padding-right: 0;
  }

  .sidebar-elem-border:first-child {
    margin-left: -15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .sidebar-elem-border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-left: 15px;
  }

}