.date_selector{
  display: inline-block;
  margin-top: 25px;
}

.date_selector_element {
  display: inline-block;
  font-size: 1.3em;
  margin-right: 10px;
  color: #333333;
}

.separator{
  font-size: 1.6em;
  color: #e6e8e7 ;
  display: inline-block;
  margin-right: 10px;
}

.button {
  cursor: pointer;
  font-size: 1.6em;
}

.date_selector_arrows{
  margin-right: 5px;
  display: inline-block;
}