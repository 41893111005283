.hour_column {
  display: inline-block;
  width: 5%;
  vertical-align: top;
  border-top: 2px solid #f2f2f2;
  .hour {
    background-color: white;
    text-align: center;
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
  }
}