.calendar_day_date_header {
  border: 1px solid #f2f2f2;
  height: 50px;

  .hour_column {
    display: inline-block;
    width: 5%;
    height: inherit;
    background-color: white;
    vertical-align: bottom;
    border: inherit;
  }

  .day {
    display: inline-block;
    width: 95%;
    height: inherit;
    text-align: center;
    background-color: white;
    vertical-align: bottom;
    padding-top: 10px;
    border: inherit;
  }
}