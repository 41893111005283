@font-face {
  font-weight: normal;
  font-style: normal;
}

.customer_search-container {

  margin: 100px 56px 52px;
  min-width: 900px;
  color: #333333;

  .button-blue-full-bg {
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: white;
    width: 245px;
    height: 47px;
    background: #1E7BE2;
    border-radius: 48px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    border-style: none;
  }

  .view_more-btn {
    margin: 20px auto 0;
    display: block;
  }

  .button-blue-no-bg {
    background-color: white;
    border: 1px solid #1E7BE2;
    border-radius: 48px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1E7BE2;
    min-height: 40px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    line-height: 24px;
    padding-left: 20px;
    padding-right: 20px;
    white-space: normal;
  }

  fieldset {
    margin-top: 20px;
    border: 1px solid #BFBFBF;
    border-radius: 3px;
    padding: 30px 53px 34px;
  }

  legend {
    font-variant: small-caps;
    width: auto;
    padding: 0 13px 10px 13px;
    margin-left: -13px;
    font-size: 26px;
  }

  .no-results {
    font-size: 20px;
    text-align: center;
    margin-top: 130px;
  }

  h1 {
    margin-left: -2px;
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin-top: 30px;
    padding-bottom: 40px;
  }

  .customer_search-form {

    legend img {
      width: 25px;
      height: 23px;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    .button-blue-full-bg:disabled {
      color: lightgrey;
      opacity: 0.65;
    }

    .form_input-wrapper {
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 5px;
      }

      input[type=text] {
        height: 46px;
        width: 100%;
        border: 1px solid #B3B3B3;
        border-radius: 3px;
        margin-bottom: 20px;
        padding-left: 16px;
      }
    }
  }

  .customer_search-result {

    .column_header {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      min-height: 50px;
    }

    .customer-row {
      word-wrap: break-word;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 48px;
      min-height: 70px;
      align-items: center;
      color: #595959;
      border-top: 1px solid #E6E6E6;
    }

    .customer-row > .col, .column_header > .col {
      max-width: calc(100% / 7);
    }

    .customer-row:last-of-type {
      border-bottom: 1px solid #E6E6E6;
    }

    .result-content {
      margin-left: 10px;
    }

    fieldset {
      min-height: 480px;
      padding-bottom: 20px;
      padding-top: 10px;
    }

  }

  .customer_information {

    legend img {
      width: 25px;
      height: 23px;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    .button-blue-full-bg:disabled {
      color: lightgrey;
      opacity: 0.65;
    }

    .form_input-wrapper {
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 5px;
      }

      input[type=text] {
        height: 46px;
        width: 100%;
        border: 1px solid #B3B3B3;
        border-radius: 3px;
        margin-bottom: 20px;
        padding-left: 16px;
      }
    }
  }

  .back-to-overview {
    display: inline-block;
    cursor: pointer;
    padding-top: 2.3em;
    padding-bottom: 20px;
    font-size: small;
    color: #1E7BE2;
  }

  .new-appointment-info-btn{
    margin-top: -20px;
  }
}