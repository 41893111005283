.error-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);

    .error-container {
        position: relative;
        width: 100%;
        max-width: 600px;
        margin: 50px auto;
        background-color: #FFF;
        padding: 20px;
        border-radius: 4px;
    }

    .error-data {
        overflow: visible;
        font-size: 9px;
        color: #AAA;
        white-space: break-spaces;
    }
}