.cancel-appointment-modal-container {

  .background_greyed {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: auto;
    z-index: 3;
  }

  .cancel-appointment-modal {
    background: #fff;
    margin: 15% auto auto;
    padding: 20px;
    max-width: 460px;
    border-radius: 0.2rem;

    h1 {
      font-size: 1.2rem;
      font-weight: bold;
    }

    .cancel-dropdown-label {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }

    .dropdown-prevalidation {
      font-size: 0.9rem;
      color: rgb(227, 91, 105);
      margin-bottom: 0.5rem;
    }

    .back_button {
      display: inline-block;
      color: #1e7be2;
      background-color: white;
      border: 1px #1e7be2 solid;
      border-radius: 35px;
      width: 200px;
      height: 35px;
      margin-right: 20px;
    }

    .back_button:active, .back_button:focus {
      outline: none;
    }

    .confirm_button {
      display: inline-block;
      color: white;
      background-color: #1e7be2;
      border-radius: 35px;
      border-color: #1e7be2;
      width: 200px;
      height: 35px;

    }

    .confirm_button:active, .confirm_button:focus {
      outline: none;
    }

    button:disabled {
      color: white;
      background-color: #8298c5;
      border-color: #8298c5;
    }

    button {
      border-style: solid;
    }

    .css-2b097c-container {
      margin-bottom: 0.7rem;
    }

    .css-yk16xz-control {
      height: 35px;
      cursor: pointer;
    }

    .css-1uccc91-singleValue {
      font-size: 0.8rem;
      line-height: 24px !important;
    }

    .css-26l3qy-menu {
      margin-top: 0 !important;
    }

    .css-6c8979-MenuList, .css-4ljt47-MenuList {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    .css-6c8979-MenuList > *, .css-4ljt47-MenuList > * {
      font-size: 0.8rem !important;
      cursor: pointer;
    }

    .css-1okebmr-indicatorSeparator {
      background: none;
    }

    .css-9gakcf-option {
      background-color: white;
      font-weight: bold;
      color: black;
    }

    .css-1wa3eu0-placeholder {
      font-size: 0.8rem;
      color: #aaa;
    }

    .close-popup {
      font-size: 2rem;
      margin-top: -20px;
      cursor: pointer;
    }

    .css-1pahdxg-control {
      border-color: #007eff;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 0 3px rgba(0, 126, 255, .1);
      background: #fff;
      cursor: pointer;
    }

  }
}